/* General CSS */
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');

.unfix-ios-bg {
    @supports (-webkit-touch-callout: none) {
        background-size: cover;
        background-repeat: no-repeat;
        background: fixed;
    }
}

*{
    font-family: "Cardo", serif;
  }

a{
    color: #fff !important;
    text-decoration: none !important;
}

.h1C{
    font-size: 40px;
    margin: 20px 0px;
    text-align: center;
    padding: 5px 10px;
    font-weight: 400;
    
}

.h2C{
    font-size: 32px;
    margin: 20px 0px;
    padding: 5px 0px;
    font-weight: 400;    
}


.h4C{
    color: white;
    padding-left: 10px;
    overflow: hidden;
}

.h5C{
    font-size: 20px; 
    margin-top: 5px;
    font-weight: 400;
}

.h6C{
    font-size: 18px;
    line-height: 30px;
}

.h6C.Local{
    color: white;
    font-weight: 550;
}

p{
    font-size: 18px;
}

.card-work-button{
    padding: 10px 10px 10px 15px;
    font-size: 20px;
    background: none;
    border: none;
    background-color: #486F55;
    border-radius: 10px;
    color: white;
    width: fit-content;
}

.card-work-button.cart{
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.list-icon{
    margin-right: 5px;
}

.Homepage{
    height: 100vh;
    
}

.Homepage-main:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png") no-repeat;
    background-size: cover;
  }

.Homepage-Section{
    padding: 70px; 
    position: relative;
    overflow: visible;
}

.Section-main{
    padding: 100px 70px 70px ;
    /* background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("./Images/AboutUs/banner-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}

.Section-main:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png") no-repeat;
    background-size: cover;
  }

.tagline{
    font-size: 48px;
}

.divider{
    height: 2px;
    width: 100%;
    background-color: white;

}

.blog-section{
    background-color: rgba(0,0,0,0.4);
    border-radius: 0px 25px 25px;
}

/* HomePage CSS */

/* Home Page Section1  */
.Section1-Main {
    position: relative;
    overflow: hidden; /* Hide overflowing content */
}

.video-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the container */
    background-attachment: fixed;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.23); /* Black overlay with 50% opacity */
    z-index: 1; /* Overlay should be above the video */
}


.row.N {
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 100%;
    z-index: 2; /* Ensure content is above the overlay */
    color: white; /* Or any color that contrasts with your video */
    margin-right: 0px;
    margin-left: 0px;
}

.header-content{
    background: transparent;
    background-color: rgba(0, 0, 0 , 0.6);
    border-radius: 20px;
}

.Section1-header {
    overflow: hidden;
    position: absolute;
    top: 35%;
    left: 0%;
    width: 100%;
    font-size: 40px;
    color: white;
    font-weight: 400;
    text-align: center;
}

/* Homepage Section3 */

.Card-Section {
    display: flex;
}

.card-work {
    width: 100%;
    padding: 30px;
    background-color: rgba(0, 0,0,0.5);
    position: relative;
    overflow: hidden;
    color: white;
    transition: transform 1.5s, box-shadow 0.3s;
    border-radius: 20px;
}


.card-work:hover {
    transform: translateY(-10px); 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.card-work:hover::after {
    transform: translate(0, 0); /* Slide overlay from bottom-left */
}

.card-image {
    background-color: #435332;
    border-radius: 50%;
    display: flex;
    height: 75px;
    place-items: center;
    width: 75px;
}

.Section3-h5 {
    font-size: 24px;
    padding: 5px 0px;
    margin-top: 20px;
    font-weight: 450;
}

.Section3-p {
    margin-top: 20px; 
    line-height: 25px;
    font-size: 18px;
    text-align: left;
    font-weight: 400;
}

.container3 {
    display: flex;
    justify-content: center;
    width: 100%;
}

.details3 {
    width: 60%;
    padding: 20px;
}

.bullet1 {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: gray;
    margin-right: 10px;
}

.bullet1.active-bullet {
    background-color: rgb(108, 132, 67); /* Highlight bullet when option is selected */
}

.options3 {
    font-size: 20px;
    padding: 20px 20px; 
    align-content: center;
}

.option {
    padding: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background-color: rgba(0, 0, 0,0.7);
    color: white;
    display: flex;
    align-items: center;
}

.option.press{
    padding: 5px;
    background: transparent;
    display: block;
    display: flex;
    height: 100px;
}

.option.active {
    background-color: rgba(72, 111, 85 , 0.7);
    color: white;
    position: relative;
}

/* Home Page Section 4 */


.Section4-div1-content{
    padding: 0px 30px 0px 0px;
    /* background-color: white; */
}

.image{
    padding: 5px;
    border-radius: 30px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Section4-h1{
    font-size: 40px;
    margin-top: 10px;
    text-align: left;
    padding: 5px 0px;
    font-weight: 400;
    color: white;
}

.Section4-h4{
    margin: 0;
    line-height: 40px;
    font-weight: 400;
    color: white;
}

.Section4-h6{
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin-top: 20px;
    line-height: 28px;
    color: white;
}

.Numbers-div{
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin: 0px 30px 0px 0px;
}

.Numbers-value{
    position: relative;
    border-top: 2px solid #ffffff;
    padding: 15px;
    margin: 30px 10px 0px 10px;
    color: white;
    height: fit-content;
}

.Numbers-value:hover{
    background-color: #ffffff;
    border-top: 2px solid #45523e;
    color: #000;
    border: none; 
    /* height: 150%; */
}

.Section4-div2-h6{
    font-size: 24px;
    text-align: left;
      
    line-height: 40px;
}

.Section4-div2-h6.S{
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}

/* HomePage Section 6 */

.Main-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.card1 {
    height: 350px;
    padding: 20px;
    background-color: rgba(0, 0, 0 , 0.4);
    position: relative;
    color: white;
    overflow: hidden;
    transition: transform 1.5s, box-shadow 0.3s;
    border-radius: 20px;
}

.card1::before,
.card1::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    background-image: linear-gradient(145deg,#435332da,#a97346da),url(https://trydo.rainbowit.net/assets/images/pattern-1.png); /* Dark overlay color */
    transition: transform 0.5s;
    z-index: -1;
}

.card1::before {
    transform: translate(-100%, -100%);
}

.card1::after {
    transform: translate(100%, 100%);
}

.card1:hover {
    transform: translateY(-10px); /* Move card1 up slightly on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    color: white;
}

.card1:hover::after {
    transform: translate(0, 0); /* Slide overlay from bottom-left */
}

.card-image{
    background-color: #435332;
    border-radius: 50%;
    display: grid;
    height: 75px;
    place-items: center;
    width: 75px;
}

.Section6-h1{
    font-size: 40px;
    font-weight: 400;
    margin-top: 10px;
    padding: 5px 0px;
    text-align: right;
    color: white;
      
}

.Section6-h5{
    font-size: 24px;
    padding: 5px 0px;
    margin-top: 20px;
}

.Section6-p{
    margin-top:20px; 
    font-size:18px;
    line-height: 28px;
    text-align: left;
}

/* Home Page Section 7  */
.Secton7-content{
    padding: 20px;
    border-radius: 20px;
}

.Section7-h1{
    font-size: 40px;
    margin: 20px 0px;
    text-align: center;
    font-weight: 400;
}

.Section7-h4{
    margin: 20px 0px;
    text-align: left;
    padding: 5px 0px;
    font-weight: 400;
}

.Content{
    display: flex;
    margin-top: 10px;
    justify-content: left;
    align-items: center;
}

/* Homepage Section7 */


/* Homepage Section 9 */
.Section9-header{
    padding: 5px 0px;
    font-size: 40px;
    font-weight: 400;
    color: white;
      
}

.Cources{
    display: flex;
}

.Course-card{
    position: relative;
    width: 50%;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 20px;
}

.Course-card::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 7px; /* Adjust thickness of the line */
    background-color: #435332; /* Line color */
    transition: width 0.7s ease; /* Transition effect */
  }
  
  .Course-card:hover::before {
    width: 100%; /* Expand width on hover */
  }

.Course-Image{
    padding: 15px;
    width: 100%;
    height: 50%;
    object-fit: cover;
}

.Section9-h4{
    font-size: 22px;
    font-weight: 400;
    padding: 15px;
    margin: 0px;
}

.Section9-p{
    font-size: 18px;
      
    font-weight: 400;
    padding: 5px 15px;
    color: #EFEFEF;
    margin: 0px;
}

.Price{
    padding: 5px 15px;
    font-weight: 400;
    margin: 0px;
}
  


/* Slider CSS */
.Slider-Main {
    width: 100%;
    margin: 0;
    border-radius: 30px;
    padding: 0px 0px 0px 0px;
  }
  
  .slide-item {
    position: relative;
  }
  
  .slide-item img {
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    object-fit: cover; /* Ensure the image covers the entire area */
  }
  
  .Slider_overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity here (0.3 is 30% black) */
  }

  .Slider_overlay-content1 {
    position: absolute;
    top: 40%; /* Adjust as needed */
    left: 0%; /* Adjust as needed */
    text-align: Right;
    width: 100%;
    color: #fff;
  }


/* Homepage Header Section */
.header-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Courses Product */
.card1.course{
    width: 50%;
    height: 500px;
    padding: 5px;
    cursor: pointer;
}

.card1.course.test{
    width: 95%;
    height: 500px;
    padding: 5px;
    cursor: pointer;
}

.card1.course.test:hover {
    transform: translateY(0px); /* Move card1 up slightly on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    color: white;
}



.card1.course.test img{
    height: 50%;
}


.card1.course.new{
    width: 40%;
    height: fit-content;
}

.Course-Image.new{
    height: 300px;
}


/* Book Section Homepage */
.Book-Image{
    width: auto;
    height: 65%;
    position: absolute;
    top: 23%;
    left: 40%;
}

/* Automated Cuorses */

.Automated_data{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.Automated_data1{
    padding: 15px;
    border-radius: 25px;
    background-color: rgba(0, 0, 0 , 0.4);
}

.Automated-content{
    background-color: #E6EFEB;   
}

.Why-Section2{
    background-color: rgba(72, 111, 85 , 0.7);
}

/* Live Courses */

.live-card{
    position: relative;
}

.live-content{
    color: white;
    background-color: rgb(0,0,0 ,0.4);
    border-radius: 25px;
}

.live-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Local Cources */
.local-content{
    background-color: rgba(0, 0, 0 ,0.4);
    color: white;
    border-radius: 25px;    
}

/* Reiki Cources */
.Reiki-content{
    background-color: rgba(0,0,0,0.4 );
    color: white;
    border-radius: 25px;
}

/* Product */

.card1.P{
   height: 550px;
   width: 35%;
   margin-right: 15px;
   padding: 5px;
}

.Course-Image.P{
    height: 75%;
    width: 100%;
    padding-bottom: 0px;
    object-fit:cover;
    border-radius: 25px;
}

.Price-button.P{
    top: 65%;
}

/* Our Team */
.row.ourteam{
    /* background-color: rgba(0,0,0,0.5);
    padding: 20px; */
    border-radius: 25px;
}

.overlay{
    opacity: 0;
    position: absolute;
    top: 30%;
    width: 90%;
    display: flex;
    justify-content: center;
    transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition for both properties */
    transform: translateY(-100%); /* Start above the view */
}
.team-member {
    position: relative;
    overflow: hidden;
    padding: 20px;
    background-color: rgba(0, 0, 0 , 0.7);
    height: 600px;
    border-radius: 25px;
}

.team-image{
    height: 80%;
    object-position: top;
}


.team-member:hover .team-image{
    opacity: 0.50;
}

.team-member:hover .overlay{
    opacity: 1;
    transform: translateY(0); 
}

/*  Blog Page */


/* Blog Detials Page */
.Section-main.blog{
    padding-top: 150px;
}

.blog-detail-card{
    height: 400px;
    border-bottom: 2px solid white;
    margin-top: 20px;
}

.Author{
    background-color: rgba(0,0,0,0.5);
}

.Latest-blogs{
    background-color: rgba(0,0,0,0.3);
}

/* Course Data */
.Course_data1{
    padding: 10px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 25px;
}

.Mentor-Data{
    background-color: rgba(0,0,0,0.4);
    border-radius: 25px;
    padding: 5px;
}

.Success-Story{
    background-color: rgb(245, 236, 229 , 0.2);
    border-radius: 20px;
    width: 95%;
}

.Success-Story-content{
    position: absolute;
    left: 35%;
    top: -5%;
    
}

.Success-Story-content1{
    padding: 20px;
    width: 70%;
    color: white;
    background-color: rgba(0, 0, 0 , 0.7);
    border-radius: 20px;
}

.Success-Story-content2{
    padding: 20px;
    margin-top: 3%;
    width: 105%;
    background-color: rgba(0, 0, 0 , 0.7);
    border-radius: 20px;
}

.card-work-button.blog{
    position: absolute;
    left: 23%;
    width: 50px;
}

.category-select{
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
}



.row.Course_data2{
    background-color: rgba(0,0,0,0.3);
    padding: 20px;
    height: 350px;
    border-radius: 25px;
}

.row.Course_data2 img{
    height: 280px;
}

.Reiki_data1{
    background-color: rgb(0,0,0 ,0.5);
    height: fit-content;
    max-height: 650px;
    border-radius: 25px;
    overflow: hidden; /* Hide scrollbars */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;
}

.Reiki_data1.test{
    width: 70%;
    background-color: rgb(0,0,0 ,0.5);
    height: fit-content;
    max-height: 1100px;
    border-radius: 25px;
    overflow: hidden; /* Hide scrollbars */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;
}

.Appointement{
    width: 30%;
    margin-left: 20px;
    height: min-content;
}

.Appointement-form{
    background-color: rgba(0,0,0,0.4);
    background-size: cover;
    padding: 20px;
    border-radius: 25px;
}

.Pricing{
    margin-bottom: 15px;
    border-radius: 25px;
    padding: 20px;
    background-color: rgba(0, 0, 0 , 0.7);
}


.Gallery-images{
    padding: 10px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
}

/* Press & Media */
.row.video{
    background-color: rgb(0,0,0 ,0.3);
    /* background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.4)),url("./Images/Wave.jpg");
    background-repeat: no-repeat;
    background-size: 50% 100%; */
    border-radius: 25px;
    background-position: right;
}

.Youtube_video{
    width: 100%;
    height: 100%;
    animation: slideInFromTop 1s ease-out;
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.modal-content.one{
    background-color: rgba(0,0,0 ,0.8);
    margin-top: 100px;
    width: 550px;
}

.modal-dialog{
    max-width: fit-content;
}

/* Styles for modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 70px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('./Images/AboutUs/banner-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: hidden; /* Optional: Ensures no content overflows */
}

/* Styles for modal content */
.modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff; /* White background for the modal content */
    padding: 10px;
    border-radius: 8px;
    overflow: hidden; /* Ensure content doesn't overflow */
}

.modal-data {
    position: relative;
    align-items: center;
    height: fit-content;
    padding: 10px;
    border-radius: 8px;
    overflow: hidden; /* Ensure content doesn't overflow */
}

.modal-controls{
    display: flex;
    justify-content: end;
}

/* Styles for image in the modal */

.Full-Screen-Image {
    transition: transform 0.3s ease;
    max-width: fit-content;
    height: 100vh;
    object-fit :cover;
}

.iiz__img{
    max-height: 100vh !important;
}

.Gallery-Image1{
    border-radius: 25px;
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.Gallery-Image2{
    border-radius: 25px;
    max-height: 350px;
    object-fit: cover;
}
.Gallery-Image3{
    border-radius: 25px;
    max-height: 370px;
    object-fit: cover;
}


.card-work-button.control{
    padding: 10px;
    height: fit-content;
}

/* Product_Detail Page */
.Product_Data{
    background-color: rgba(0, 0, 0 , 0.6);
    border-radius: 25px;
    height: fit-content;
    padding : 25px 0px;
}


.cart-count {
    background-color: #486F55;
    color: white;
    border-radius: 50%;
    padding: 0 6px;
    font-size: 14px;
    position: absolute;
    top: -5px;
    right: 0px;
}


.Cart-row{
    background-color: rgb(0,0,0 ,0.5);
    padding: 10px 0px;
    border-radius: 15px;
    max-height: fit-content;
    overflow-x: visible;   
}

.cart-row{
    background-color: rgba(255, 255, 255 , 0.2);
    padding: 10px;
    border-radius: 25px;
    
}

.Cart_Image{
    max-width: 100%;
    min-width: 50%;
    object-fit: contain;
}

.Checkout{
    background-color: #435332;
    padding: 10px;
    height: fit-content;
}

.Checkout-row{
    background-color: rgb(0,0,0 ,0.5);
    margin : 0px 5px 5px 5px;
    padding: 10px 5px;
    border-radius: 15px;
}

.Custom-hr{
    height: 5px;
    background-color: black;
}

.Bookcall{
    background-color: rgba(0, 0, 0 , 0.6);
    border-radius: 25px;
}

.Slot-data{
    color: white;
    display: flex;
    justify-content: center;
    font-size: 16px;
    border: 1px solid #fff;
    cursor: pointer;
}

.Slot-data.selected {
    background-color: #435332; /* Change to your desired color */
    color: #fff;
  }

.User_Details{
    background-color: rgba(0, 0, 0 , 0.7);
    padding: 20px;
    border-radius: 25px;
}

.Section-main.Checkout{
    padding: 20px 70px;
    background-color: rgba(255, 255, 255 , 0.2);
}

/* Star Rating */
.Stars{
    display: flex;
    flex-wrap: wrap;
}

.star-rating {
    direction: rtl;
    display: inline-block;
    padding: 20px;
    cursor: default;
  
    input[type="radio"] {
      display: none;
    }
  
    label {
      color: #bbb;
      font-size: 1.5rem;
      padding: 0;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  
    label:hover,
    label:hover ~ label,
    input[type="radio"]:checked ~ label {
      color: #486F55;
    }
  }

/* Quiz Modal */

.modal-overlay.quiz{
    background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
}

.modal-content.quiz{
    width: 40%;
    background: rgba(0,0,0 ,0.5);
    height: fit-content;
    padding: 25px;
    border-radius: 20px;
}


.option input[type="radio"] {
    margin-right: 10px; /* Space between radio button and label text */
    cursor: pointer;
}

.option label {
    cursor: pointer; /* Ensure label text is clickable */
}

.search-input{
    background: transparent;
    color: white;
}

.search-input::placeholder{
    color: white;
    opacity: 1;
}

.filters{
    justify-content: center;
}

.category-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.category-button {
    background-color: rgba(0, 0, 0 , 0.7);
    color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.category-button.active {
    background-color: rgba(72, 111, 85 , 0.7);
    color: white;
    border: 1px solid #ad5d50;
}


.Service-card{
    background-color: rgba(0, 0, 0 , 0.7);
    color: white;
}

.Service-Image{
    width: 35%;
}

.Service-Content{
    width: 65%;
}


.slide-content.Success{
    background-color: rgb(0,0,0 ,0.5);
    width: 95%;
    border-radius: 25px;
    height: 350px;
}

.Automated-CTA-Text{
    color: white;
}

.blog-end-div.Automated{
    clip-path: polygon(0 1%, 77% 0, 100% 100%, 0% 100%);
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 75%;
}

.blog-end-div.Automated2{
    height: 100%;
    width: 25%;
    align-items: center;
}

.Mentor-Details{
    height: 550px;
    overflow: hidden; /* Hide scrollbars */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}


/* Ensure the container is positioned relative to handle absolute positioning of content */
.Service-grid{
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to next row if needed */
    gap: 5px; /* Space between cards */
}

.hover-container {
    position: relative;
    overflow: hidden; /* Ensure that content doesn't overflow the container */
    background-color: rgba(0, 0, 0 , 0.4);
    width: 33%;
    height: 570px;
    padding: 20px;
    border-radius: 25px;
    margin-bottom: 20px;
}

.hover-container.hands1{
    width: 38%;
    height: 600px;
}

.hover-container.hands2{
    width: 40%;
    height: 600px;
}


/* Style for the image */
.hover-container img {
    width: 100%;
    height: 60%;
    transition: transform 0.5s ease; /* Smooth transition for image */
    border-radius: 25px;
}

.hover-container.hands img{
    height: 70%;
}

/* Style for the content */
.content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: fit-content;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    transform: translate(-50%, -50%); /* Center the content */
    opacity: 0;
    padding: 5px 5px 5px 15px;
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition for content */
    text-align: center; /* Center the text */
}

.hover-container:hover .Main-Title{
    display: none;
}

.hover-container:hover{
    height: 600px;
}

/* Hover effect */
.hover-container:hover img {
    transform: scale(1.1); /* Scale image to make it go into the background */
    height: 600px;
    opacity: 0.70;
}

.hover-container.test:hover img {
    transform: scale(1.1); /* Scale image to make it go into the background */
    height: 640px;
    opacity: 0.70;
}

.hover-container.hands1:hover img,
.hover-container.hands2:hover img{
    height: 600px;
}

.hover-container:hover .content {
    background-color: rgb(0,0,0 ,0.4);
    opacity: 1; /* Show the content */
    height: 100%;
    transform: translate(-50%, -50%) scale(1); /* Ensure content is centered */
}

.Main-Title{
    height: 40px;
}

.Main-Title.Subtitle{
    height: 90px;
}

.Notfound-h1{
    font-size: 84px;
}

/* Blog CSS */
.Blogpage-main{
    position: relative;
    overflow: visible;
    padding-top: 90px;
    /* background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("./Images/AboutUs/banner-1.png");
    
    background-size: cover;
    background-attachment: fixed; */
}

.Blogpage-main:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png") no-repeat;
    background-size: cover;
  }

.blog-banner{
    width: 100%;
    object-fit: cover;
}


.blog-end{
    padding: 40px ;
    height: 200px;  
    width: 100%;
    background-color: #6b996b;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url('./Images/blog-background1.jpg');
    background-size: contain    ;
}

.blog-end-div{
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2%;
    margin-left: 4%;
    animation: slideInLeft 2.5s forwards; /* Animation for left cards */
}

.blog-end .blog-input{
    width: 300px;
    height: 50px;
    background-color: rgba(245, 245, 245, 0.9);
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
      
}


.blog-end-div-text{
    display: flex;
    padding-top: 2%;
    margin-left: 2%;
    width: 50%;
    animation: slideInLeft 2.5s forwards; /* Animation for left cards */
}

.blog-end-text2{
    font-size: 28px;
    padding-left: 5%;
    color: white;
    text-align: left;
    margin: 0;
    width: 100%;  
    font-weight: normal; /* Reset font weight to normal */
    animation: slideInRight 2.5s forwards; /* Animation for left cards */
}

.blog-card-test{
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    height: 500px;
    width: 32%;
    margin-top: 120px;
}

.blog-divider{
    height: 2px;
    width: 35%;
    background-color: white;
    margin: 0px 25px;
}

.blog-test-image{
    top: calc(-22%); 
    left: 12%;
    border-radius: 25px;
    height: 45%;
}

.blog-test-content{
    padding-top: 135px;
}

.blog-grid {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of items to the next row */
    gap: 10px; /* Optional: space between the blog cards */
    padding: 0px 40px;
  }

.blog-title{
    height: 90px;
}

.blog-content{
    height: 145px;
    overflow: hidden;
}

.filters{
    display: flex;
}

/* Contact Us Page */
.Contact-main{
    position: relative;
    overflow: visible;
    padding-top: 100px;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../Assets/Images/AboutUs/banner-1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}

.Contact-main:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png") no-repeat;
    background-size: cover;
  }

.col-12.col-xl-7.Contact{
    padding-right: 0;
}

.col-12.col-xl-5.Contact{
    padding-left: 0;
}

.row.contact{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 35px;
}

.Contact-head{
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    overflow: visible;
    position: relative;    
}


.Contact-middle{
    padding: 30px 70px 80px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Contact-form{
    /* background-color: rgba(169, 181, 127 , 0.4); */
    padding: 35px;
    border-radius: 25px 0px 0px 25px;
}

.Contact-form-input{
    width: 100%;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px 10px 0px;
    background-color: transparent;
    color: white;
    border: none;
    outline: none;
    border-bottom: 2px solid #f5ece2;
}

.Contact-form-input:active{
    border: none;    
}

.Contact-form-input::placeholder{
    color: white;
    opacity: 1;
}

.Contact-form-input:focus::placeholder {
    display: none;
}

.Contact-form-input option {
    background-color: #000; /* Change to your desired option background */
    color: #fff; /* Change to your desired option text color */
}

input:focus {
    &::-webkit-input-placeholder {
       color: transparent;
       -webkit-transition: color 0.2s ease;
       transition: color 0.2s ease;
    }

    &:-moz-placeholder { /* Firefox 18- */
       color: transparent;
       -webkit-transition: color 0.2s ease;
       transition: color 0.2s ease;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
       color: transparent;
       -webkit-transition: color 0.2s ease;
       transition: color 0.2s ease;
    }

    &:-ms-input-placeholder {  
       color: transparent;
       -webkit-transition: color 0.2s ease;
       transition: color 0.2s ease;
    }
  }


.Contact-data{
    margin-left: 2%;
    font-weight: 400;
    font-size: 20px;
}

.Contact-details{
    padding: 70px 20px;
    border-radius: 0px 25px 25px 0px;
    height: 100%;
    color: white;
    /* background-color: #45523E; */
}

.Contact-email{
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
}

/* About Us Page */
.AboutUs-main{
    position: relative;
    overflow-x: hidden;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; Fix the background image in place */
}

.AboutUs-main:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../Assets/Images/AboutUs/banner-1.png") no-repeat;
    background-size: cover;
  }


.AboutUs-Head{
    display: flex;
    justify-content: center;
    padding-top: 150px;
    color: white;
    position: relative;
}

.AboutUs-head-details{
    border-radius: 40px 0px;
    padding: 30px;
    color: white;
    background-color: rgba(0,0,0,0.5);
    line-height: 30px;
}


.Aboutus-head-img{
    width: 95%;
    height: auto;
    object-fit: cover;
}

.aboutus-p{
    font-size: 18px;
}

.AboutUs-mid-details{
    padding: 20px;
    font-size: 16px;
    color: white;
    /* border-right: 10px solid #ad5d50; */
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px 0px;
    animation: slideInFromRight 1.5s ease-out;
    /* color: #fff; */
}


.aboutus-middle4{
    text-align: left;
    padding: 50px 20px;
    background: transparent;
    display: flex;
    
}

.aboutus-middle4-content{
    padding: 30px;
    line-height: 30px;
    color: white;
    /* border-right: 10px solid #ad5d50; */
    background-color: rgba(0,0,0,0.5);
    border-radius: 30px 60px;
    animation: slideInFromLeft 1.5s ease-out;
}

.aboutus-middle4-img{
    width: 40%;  
}

.iiz{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.form-control.cart{
    background-color: transparent;
    color: white;
    border: none;
    width: 60%;
}

.form-control.cart:focus{
    border: none;
    color: white;
    background-color: transparent;
    box-shadow: none;
}

.cart-icon{
    cursor: pointer;
}


@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


@media (max-width:768px) {

    .cart-row .h4C {
        white-space: normal; /* Allow text to wrap */
        overflow: visible; /* Ensure no overflow is hidden */
        text-overflow: clip; /* Disable ellipsis */
    }

    /* Home Page Section1 */
    .Section1-Main {
        height: 95vh;
    }

    .Section1-header {
        top: 35%;
        left: 4%;
        text-align: left;
        width: 90%;
    }

    .Section4-main{
        padding: 15px
    }
    
    .Section4-div1-content{
        margin-top: 30px;
        width: 100%;
        padding: 15px;
    }

    .Section4-h1{
        font-size: 36px;
    }

    .Section4-h6{
        font-size: 18px;
        line-height: 30px;
    }

    .Numbers-div{
        display: block;
    }

    /* Section 6 HomePage */
    .Card-Section{
        display: block;
    }

    .Main-section{
        display: block;
        padding: 0;
    }

    .Section6-h1{
        text-align: left;
        font-size: 36px;
    }

    .card1{
        width: 100%;
        margin: 20px 0px;
    }

    /* Service Details Page */
    .Service-grid{
        display: block;
        padding: 0px 10px;
    }

    .hover-container{
        width: 100%;
    }

    .hover-container.hands1{
        width: 100%;
        height: 600px;
    }

    .hover-container.hands2{
        width: 100%;
    }

    .hover-container.hands2:hover{
        height: 800px;
    }
    

    .hover-container.hands2:hover img{
        height: 800px;
    }
    

    .Service-grid{
        padding: 0px;
    }

    .blog-end-div.Automated{
        clip-path: none;
       height: 100%;
        width: 100%;
        background : none;
        padding-left: 10px;
    }

    .blog-end-div.Automated2{
        width: 100%;
        padding-bottom: 10px;
        align-items: start;
        padding-left: 20px;
    }

    /* Blog Page */
    .modal-overlay{
        padding: 10px;
    }

    .Full-Screen-Image{
        height: 100%;
    }

    .blog-grid{
        display: block;
        padding: 0px 10px;
    }

    .blog-card-test{
        width: 100%;
        margin-left: 0px;
    }

    .filters{
        display: block
    }

    .blog-end{
        display: block;
        height: auto;
    }

    .blog-end-div{
        margin-left: 0%;
        margin-top: 5%;
    }

    .blog-end-div-text{
        display: block;
        width: 95%;
    }

    .blog-end-text2{
        width: 100%;
        padding: 10px 0 0 0 ;
        text-align: left;
    }  

    /* About Us Page */
    .AboutUs-main{
        min-height: 100vh;
    }

    .AboutUs-head-details{
        width: 100%;
    }

    .AboutUs-Head{
        padding: 80px 25px;
        display: block;
    }

    .Aboutus-head-img{
        width: 100%;
    }
   
    .AboutUs-mid{
        display: block;
        padding: 40px 25px;
    }

    .AboutUs-mid-imagediv{
        width: 100%;
    }

    .AboutUs-mid-details{
        width: 100%;
    }

    .aboutus-middle3{
        display: block;
        padding: 40px 25px;
    }

    .aboutus-middle3-content{
        width: 100%;
    }

    .aboutus-middle3-image{
        width: 100%;
        margin-left: 0%;
    }

    .middle3-image{
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    .aboutus-middle4{
        display: block;
        padding: 40px 25px;
    }

    .aboutus-middle4-img{
        width: 100%;
    }

    .aboutus-middle4-content{
        width: 100%;
    }

    .aboutus-tick{
        width: 70px;
        height: 40px;
    }

    /* Contact Page */
    .Contact-main{
        background-size: cover;
    }

    .Contact-head{
        padding: 15px;
    }
    
    .Contact-middle{
        padding: 20px;
    }

    .col-12.col-xl-7.Contact{
        padding: 0px
    }

    .Contact-form{
        padding: 25px 15px 15px 15px;
        width: 100%;
        border-radius: 20px 20px 0px 0px;
    }

    .col-12.col-xl-5.Contact{
        padding: 0px;
    }

    .Contact-details{
        border-radius: 0px 0px 20px 20px;
    }

    .Contact-data{
        font-size: 18px;
    }

    .Contact-email{
        font-size: 18px
    }

    .modal-content.quiz{
        width: 90%;
    }

    .col-1.Number{
        display: none;
    }

    .filters{
        justify-content: center;
    }

    .category-buttons{
        display: block;
        margin-top: 20px;
    }

    .Section3-h1{
        margin-top: 20px;
        padding: 0px
    }

    .Section3-h4{
        padding: 0;
    }

    .container3 {
        flex-direction: column;
        align-items: flex-start;
        background : none;
    }

    .options3 {
        width: 100%;
    }

    .details3 {
        width: 100%;
        padding: 0;
    }

    .option {
        width: 100%;
    }

    .option.active + .details3 {
        display: block;
    }

    /* General */
    .Section-main{
        padding: 100px 20px 20px;
    }

    .Section-main.Checkout{
        padding: 20px;
    }

    .card1.course{
        width: 100%;
    }

    /* Homepage Header */

    .Homepage-Section{
        padding: 40px 10px;
    }

    .header-content{
        display: block;
        align-items: start;
    }

    .card1.P{
        width: 100%;
        margin-right: 0px;
    }

    .Reiki_data1.test{
        width: 100%;
        margin-bottom: 10px;
    }

    .Product-card{
        width: 100%;
        margin-top: 20px;
        margin-right: 0;
    }

    .tagline{
        font-size: 38px;
    }


    /* Live Courses */
    .live-content {
        order: 2; /* Content box comes after the image on mobile */
    }

    .live-image {
        order: 1; /* Image comes first on mobile */
        width: 100%; /* Ensure image takes full width on mobile */
        height: auto; /* Maintain aspect ratio */
    }

    /* Local Courses */
    .local-content {
        order: 1; /* Content box comes after the image on mobile */
    }

    .local-image {
        order: 2; /* Image comes first on mobile */
        width: 100%; /* Ensure image takes full width on mobile */
        height: auto; /* Maintain aspect ratio */
    }

    /* Reiki Courses */
    .Reiki-content{
        order: 2; /* Content box comes after the image on mobile */
    }

    .Reiki-image {
        order: 1; /* Image comes first on mobile */
        width: 100%; /* Ensure image takes full width on mobile */
        height: auto; /* Maintain aspect ratio */
    }

    /* Course Details */
    .col-0 {
        display: none;
    }
    
    .Mentor-Data .col-12.col-xl-8 {
        order: 2; /* Place content after the image */
    }
    
    .Mentor-Data .col-12.col-xl-4 {
        order: 1; /* Place image before the content */
    }

    .Success-Story{
        height: 1700px;
    }

    .Success-Story-content{
        left: 0%;
        top: 95%;
    }

    .Success-Story-content1{
        width: 100%;
    }

    .Reiki_data1{
        width: 100%;
    }

    .Appointement{
        width: 100%;
        margin-left: 0%;
    }

    .custom-modal-dialog {
    max-width: 800px; /* Set your desired width here */
    width: 100%; /* Ensure it takes full width on smaller screens */
    }

    .Cart-row{
        padding: 0;
    }

    .card-work-button.blog{
        left: 85%;
    }

    .section7-main{
        padding: 15px;
    }

    .Secton7-content{
        padding: auto;
    }

    .Book-Image{
        left: 10%;
        top: 0%;
        height: 30%;
    }

    .row.Section7{
        padding: 0;
    }

    .Section7-h1{
        font-size: 36px;
    }

    .Section7-h4{
        font-size: 20px;
    }

    .Section9-main{
        padding: 15px;
    }
    
    .Cources{
        display: block;
    }

    .Course-card{
        width: 100%;
    }

    .Section9-header{
        left: 15%;
        margin-top: 5%;
        font-size: 36px;
        width: 80%;
    }

    .cart-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
        white-space: nowrap; /* Prevent wrapping of child elements */
      }

    .Cart-row{
        width: 800px;
        display: inline-block;
        padding: 20px;
    }

    .hover-container.hands{
        width: 100%;
    }

    .card1.course.new{
        width: 100%;
    }

    .hover-container{
        height: 650px;
    }    

    .hover-container:hover img {
        transform: scale(1.1); /* Scale image to make it go into the background */
        height: 650px;
        opacity: 0.70;
    }

    .row.Course_data2{
        height: fit-content !important;
    }
    
    .slide-content.Success{
        height: 570px;
    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    /* Home Page Section1 */
    .video-container{
        height: 80vh;
    }
    
    .Section1-header{
        left: 5%;
        text-align: center;
        align-items: center;
        width: 100%;
    }
    
    .Section-main{
        padding: 100px 20px 20px;
    }

    .col-0{
        display: none;
    }

    .Success-Story-content{
        left: 70%;
    }

    .Success-Story-content1{
        width: 130%;
    }

    .aboutus-middle3-content{
        width: 80%;
    }
    
    .aboutus-middle3-image{
        width: 20%;
    }

    .aboutus-middle4-content{
        width: 100%;
    }

    /* Contact Page */
    .col-12.col-xl-7.Contact{
        width: 50%;
    }

    .container3{
        background: none;
    }

    .Book-Image{
        height: 50%;
        left: 50%;
    }

    .row.Section7{
        padding-left: 0;
    }

    .Cources{
        margin-top: 20%;
    }

    .Section9-header{
        left: 10%;
        right: 0%;
    }

    .card1{
        margin: 10px;
        padding: 10px;
    }
    
    

}

/* Custom Swal styles */
.custom-swal {
    background-color: rgba(0, 0, 0 , 0.4); /* Background color */
    color: #fff; /* Text color */
}

.custom-swal .swal2-title {
    color: #fff; /* Title text color */
}

.custom-swal .swal2-popup {
    background-color: rgb(0, 0, 0 );
    color: white; /* Popup background color */
}

.custom-swal .swal2-icon{
    border-color: #ffffff !important;
}

.custom-swal .swal2-info{
    border-color: #ffffff !important;
}

.custom-swal .swal2-confirm {
    background-color: #486F55; /* Confirm button background color */
    color: #fff; /* Confirm button text color */
    font-size: 18px;
}

.custom-swal .swal2-html-container{
    font-size: 18px;
}

.custom-swal .swal2-cancel {
    background-color: #ad5d50; /* Cancel button background color */
    color: #fff; /* Cancel button text color */
    font-size: 18px;
}

.custom-swal .swal2-icon {
    border-color: #007bff; /* Icon border color */
}

.blog-details{
    max-height: 1000px;
    background-color: rgba(0, 0, 0 , 0.4);
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    border-radius: 0px 25px 25px;
}

.blog-details img {
    max-width: 100%; /* Makes images responsive */
    height: auto;    /* Maintains aspect ratio */
}

.loader {
    width: 50px;
    height: 50px; /* Added height */
    aspect-ratio: 1;
    display: grid;
    position: fixed; /* Use fixed positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Centering transformation */
    z-index: 9999; /* Ensure it's above other content */
}

.loader::before,
.loader::after {    
    content: "";
    grid-area: 1 / 1;
    --c: no-repeat radial-gradient(farthest-side, #ad5d50  92%, #0000);
    background: 
      var(--c) 50% 0, 
      var(--c) 50% 100%, 
      var(--c) 100% 50%, 
      var(--c) 0 50%;
    background-size: 12px 12px;
    animation: l12 1s infinite;
}

.loader::before {
    margin: 4px;
    filter: hue-rotate(45deg);
    background-size: 8px 8px;
    animation-timing-function: linear;
}

@keyframes l12 { 
    100% { transform: rotate(.5turn); }
}

/* Full-screen overlay */
.full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998; /* Behind the loader */
}

.Success-image{
    height: 300px;
    width: auto;
    object-fit: cover;
}

.Payment{
    background-color: rgba(0, 0, 0 , 0.4);
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Contact-form label {
    font-weight: bold;
    color: #fff;
    display: block; 
    margin-bottom: 5px; 
}

textarea:focus::placeholder {
    opacity: 0; /* Hide placeholder on focus */
}

.Reiki_data1.new{
    padding: 20px 30px;
    background-color: rgb(0,0,0 ,0.5);
    max-height: fit-content;
}

@media (max-width : 768px) {
    .Reiki_data1.new{
        padding: 10px;
    }
    
}

.ant-notification-notice-close{
    background-color: black !important;
}

.result-image{
    width: 100%;
    object-fit: cover;
}


@media (max-width : 768px) {

    .result-image{
        width: 100%;
    }
    
}

@media (min-width : 1600px) {

    .team-member{
        height: 700px;
    }
}