/* Navbar Styles */
.navbar1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px 20px;
    z-index: 999;
    transition: background-color 0.5s, box-shadow 0.5s;
    overflow: visible;
}

.navbar-scrolled {
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled .navbar-nav1{
    height: 70px;
}


.navbar-nav1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90px;
    position: relative;
}

.navbar-logo{
    width: 30%;
}

.navbar-logo img {
    width: 100%;
    object-fit : cover;
    cursor: pointer;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
    color: white;
}

.menu-toggle .bar {
    width: 25px;
    height: 2px;
    background-color: #414141;
    border-radius: 2px;
}

/* Navbar menu styles */
.navbar-nav-menu {
    list-style-type: none;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    color: white;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin: 0 10px;
    position: relative;
}

.nav-link1 {
    text-decoration: none;
    color: #fff;
    padding: 10px;
    display: block;
    position: relative;
    font-size: 18px;
    font-weight: 400;
}

.nav-link1::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
}

.nav-item:hover .nav-link1::before {
    transform: scaleX(1);
}

/* Dropdown styles */
.nav-item.dropdown {
    position: relative;
}

.dropdown-menu1 {
    list-style-type: none;
    display: none; /* Hide by default */
    position: absolute;
    width: 250px;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    flex-direction: column;
    margin: 0;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure dropdown is on top */
    overflow-x: visible; /* Allow horizontal overflow */
    overflow-y: auto; /* Allow vertical scrolling if needed */
}

.dropdown-item1.active{
    background-color: rgba(69, 82, 62 , 0.7);
}

.nav-item:hover .dropdown-menu1 {
    display: block; /* Show on hover */
}

.dropdown-item1 {
    text-decoration: none;
    color: #fff;
    padding: 10px;
    display: block;
    font-size: 16px;
}

.dropdown-item1:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-Icons {
    display: flex;
}

.search-container {
    position: absolute;
    right: 0;
    top: 100%;
    background: rgb(0, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 400px;
}

.search-form {
    display: flex;
    align-items: center;
}

.search-input {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 16px;
    border-radius: 4px;
    padding: 10px;
}

.search-input.new{
    width: 100%;
}



/* Mobile Styles */
@media (max-width: 1100px) {
    .navbar-nav-menu {
        display: none;
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 999;
        overflow-y: auto;
    }
    

    .navbar-nav-menu.active {
        display: flex;
    }

    .navbar-nav-menu .nav-item {
        margin: 10px 0;
    }

    .menu-toggle {
        display: flex;
    }

    .nav-link1 {
        color: white;
    }

    .navbar-end {
        padding: 0;
    }

    .dropdown-menu1 {
        position: static; /* Reset to static for mobile */
        width: 100%; /* Make dropdown full width */
        top: 0;
        left: 0;
        box-shadow: none; /* Remove box shadow */
        z-index: 1001; /* Ensure dropdown is on top of the menu */
    }

    .navbar-scrolled .navbar-nav-menu{
        top: 90px;
    }

    .search-container{
        width: 300px;
    }

    .navbar-logo{
        width: 70%;
    }
}


.search-title{
    margin: 10px;
    font-size: 18px;
}

.suggestions-dropdown {
    position: absolute;
    background: rgba(0, 0, 0 );
    z-index: 10;
    max-height: 250px;
    width: 250px;
    overflow-y: auto;
    width: 100%; /* Adjust as needed */
    color: white;
    padding-top: 10px;
    margin-top: 2px;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid white;
}

.suggestion-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
    color: black;

}
