.footer-main{
    padding: 50px 40px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../../../Assets/Images/Footer_bg2.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: visible;
}


.footer-start{
    width:45%;
    padding:20px; 
    display:flex;
    justify-content:center;
    flex-direction:column;
}

.Footer-end{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
}

.Icons{
    margin: 20px 0px;
    display: flex;
}

.footer-h4{
    font-size: 32px;
    color:white;
    margin-top:5px;
    padding: 5px 0px;
    overflow: hidden;
}

.footer-h5{
    font-size: 28px;
    padding: 5px 0px;
    margin-top:20px; 
    color:white;
}

.footer-p{
    color:white;
    font-size:20px;
    margin-top:20px;
}


.footer-links{
    display: flex;
    margin-top: 20px;
}

.footer-button{
    color:white;
    margin-top:10px;
    font-size:18px;
    background: none;
    border: none;
    margin: 10px 20px 10px 0px;
    border-bottom: 2px solid white;
}

.footer-logo{ /* Adjust logo height */
    width: 100%;
    object-fit: cover;
    padding: 5px; /* Optional: Add padding for spacing around the image */
}

.footer-h6.S{
    font-size: 18px;
    padding: 0px 10px 5px 10px;
    color: white;   
}

.footer-divider{
    height: 2px;
    width: 100px;
    background-color: white;
}

@media (max-width:500px) {
    .footer-main{
        display: block;
        padding: 10px;
    }

    .footer-start{
        width: 100%;
        padding: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Footer-end{
        width: 100%;
    }


    .footer-logo{
      margin-left: 0px;
      align-items: center;
      object-fit: contain;
    }

    .footer-links{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .Footer-end-div{
        display: block;
    }

    .footer-button{
        width: fit-content;
        margin-top: 20px;
        border-bottom: 1px solid #e3e3e3ed;
        margin: 20px 0px 0px 0px;
    }

    .footer-contact{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Icons{
        justify-content: center;
    }
    
}


@media (min-width: 768px) and (max-width: 1200px) {
    
    .footer-button{
        margin: 0px 10px 0px 0px;
        font-size: 16px;
    }

    .footer-start{
        width: 40%;
    }

    .Footer-end{
        width: 60%;
    }

    .footer-h4{
        font-size: 28px;
    }

    .footer-p{
        font-size: 16px;
    }

}